import React, { useState } from "react";
import "../styles/VideoPlayer.css";

const VideoPlayer = () => {
  return (
    <div>
      <div class="videowrapper">
        <div class="video-main">
          <div class="promo-video">
            <div class="waves-block">
              <div class="waves wave-1"></div>
              <div class="waves wave-2"></div>
              <div class="waves wave-3"></div>
            </div>
          </div>
          <a
            href="https://www.youtube.com/"
            class="video video-popup mfp-iframe"
            target="_blank"
            data-lity
          >
            <i class="fa fa-play"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
