export const defaults = {
  name: "defaults-theme",
  colors: {
    background: "#1b4d89",
    submenu: "#1b4d89",
    border: "#1b4d89",
    text: "#1b4d89",
    submenutext: "#1b4d89",
    textwhite: "#fff",
  },
};

export const dark = {
  name: "dark-theme",
  colors: {
    background: "#505050",
    submenu: "hsl(0, 0%, 50%)",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#fff",
  },
};

export const red = {
  name: "red-theme",
  colors: {
    background: "hsl(2, 85%, 41%)",
    backgroundgradient:
      "radial-gradient(circle, hsl(0, 100%, 50%), hsl(0, 100%, 25%))",
    submenu: "hsl(6, 78%, 66%)",
    border: "hsl(0, 0%, 87%)",
    textred: "hsl(0, 100%, 25%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#fff",
  },
};

export const green = {
  name: "green-theme",
  colors: {
    background: "hsl(150, 80%, 20%)",
    submenu: "hsl(145, 63%, 27%)",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#fff",
  },
};

export const brown = {
  name: "brown-theme",
  colors: {
    background: "hsl(6, 78%, 66%)",
    submenu: "hsl(6, 78%, 66%)",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#fff",
  },
};

export const pink = {
  name: "pink-theme",
  colors: {
    background: "hsl(282, 44%, 30%)",
    submenu: "hsl(282, 44%, 42%)",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#eee",
  },
};

// new theme added

export const colorA = {
  name: "colorA",
  colors: {
    background: "#6f61c0",
    submenu: "#6f61c0",
    submenub: "#5c4b99",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#eee",
  },
};

export const colorB = {
  name: "colorB",
  colors: {
    background: "#618264",
    submenu: "#618264",
    submenub: "#79ac78",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#eee",
  },
};

export const colorC = {
  name: "colorC",
  colors: {
    background: "#1d5b79",
    submenu: "#1d5b79",
    submenub: "#468b97",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#eee",
  },
};

export const colorD = {
  name: "colorD",
  colors: {
    background: "#070a52",
    submenu: "#070a52",
    submenub: "#141e61",
    border: "hsl(0, 0%, 87%)",
    text: "hsl(0, 1%, 16%)",
    submenutext: "hsl(192, 15%, 95%)",
    textwhite: "#eee",
  },
};

export const colorE = {
  name: "colorE",
  colors: {
    background: "#9BCF53",
    submenu: "#9BCF53",
    textwhite: "#eee",
  },
};

export const colorF = {
  name: "colorF",
  colors: {
    background: "#910A67",
    submenu: "#910A67",
    textwhite: "#eee",
  },
};

export const colorG = {
  name: "colorG",
  colors: {
    background: "#FFCF81",
    submenu: "#FFCF81",
    textwhite: "#eee",
  },
};

export const colorH = {
  name: "colorH",
  colors: {
    background: "#65B741",
    submenu: "#65B741",
    textwhite: "#eee",
  },
};

export const colorI = {
  name: "colorI",
  colors: {
    background: "#F4CE14",
    submenu: "#F4CE14",
    textwhite: "#eee",
  },
};

export const colorJ = {
  name: "colorJ",
  colors: {
    background: "#793FDF",
    submenu: "#793FDF",
    textwhite: "#eee",
  },
};
