// import images from all-images/blog-img directory
import img01 from "../all-images/blog-img/blog-1.jpg";
import img02 from "../all-images/blog-img/blog-2.jpg";
import img03 from "../all-images/blog-img/blog-3.jpg";

const blogData = [
  {
    id: 1,
    title: "Campus clean workshop",
    author: "Sacred Heart",
    date: "16 Sep, 2022",
    time: "9pm",
    imgUrl: img01,
    description:
      " --------------------------------------------------------------------------------------------------",
    quote:
      "---------------------------------------------------------------------------------------------------",
  },

  {
    id: 2,
    title: "Environment Conference",
    author: "Sacred Heart",
    date: "16 Sep, 2022",
    time: "9pm",
    imgUrl: img02,
    description:
      " --------------------------------------------------------------------------------------------------",
    quote:
      "---------------------------------------------------------------------------------------------------",
  },

  {
    id: 3,
    title: "Digital Transformation Conference",
    author: "Sacred Heart",
    date: "16 Sep, 2022",
    time: "9pm",
    imgUrl: img03,
    description:
      " --------------------------------------------------------------------------------------------------",
    quote:
      "---------------------------------------------------------------------------------------------------",
  },
];

export default blogData;
