import React, { useState } from "react";
import "../styles/MultiStepForm.css";

function MultiStepForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(4);
  const [classn, setClassn] = useState("");
  const [section, setSection] = useState("");
  const [roll, setRoll] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  const handleNext = () => {
    if (currentStep === 3) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log({
      classn,
      section,
      roll,
      firstName,
      lastName,
      phone,
      profileImage,
    });
    // Reset the form
    setCurrentStep(4);
    setClassn("");
    setSection("");
    setRoll("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setProfileImage(null);
  };

  const setProgressBar = (currentStep) => {
    const percent = parseFloat((100 / steps) * currentStep).toFixed();
    return `${percent}%`;
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
          <div className="card admission_form">
            <form id="msform">
              {/* progressbar */}
              <ul id="progressbar">
                <li className={currentStep >= 1 ? "active" : ""} id="account">
                  <strong>Acadmic</strong>
                </li>
                <li className={currentStep >= 2 ? "active" : ""} id="personal">
                  <strong>Personal</strong>
                </li>
                <li className={currentStep >= 3 ? "active" : ""} id="payment">
                  <strong>Image</strong>
                </li>
                <li className={currentStep >= 4 ? "active" : ""} id="confirm">
                  <strong>Finish</strong>
                </li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: setProgressBar(currentStep) }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>{" "}
              <br />
              {/* fieldsets */}
              {currentStep === 1 && (
                <fieldset>
                  {/* Account Information */}
                  <div className="form-card">
                    <input
                      type="text"
                      name="classn"
                      placeholder="Class"
                      value={classn}
                      onChange={(e) => setClassn(e.target.value)}
                    />
                    <input
                      type="text"
                      name="section"
                      placeholder="Section"
                      value={section}
                      onChange={(e) => setSection(e.target.value)}
                    />
                    <input
                      type="text"
                      name="roll"
                      placeholder="Roll"
                      value={roll}
                      onChange={(e) => setRoll(e.target.value)}
                    />
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={handleNext}
                    />
                  </div>
                </fieldset>
              )}
              {currentStep === 2 && (
                <fieldset>
                  {/* Personal Information */}
                  <div className="form-card">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={handleNext}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={handlePrevious}
                    />
                  </div>
                </fieldset>
              )}
              {currentStep === 3 && (
                <fieldset>
                  {/* Image Upload */}
                  <div className="form-card">
                    <input
                      type="file"
                      name="profileImage"
                      placeholder="Profile Image"
                      onChange={(e) => setProfileImage(e.target.files[0])}
                    />
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Submit"
                      onClick={handleSubmit}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={handlePrevious}
                    />
                  </div>
                </fieldset>
              )}
              {currentStep === 4 && (
                <fieldset>
                  {/* Finish */}
                  <div className="form-card">
                    {/* <h2 className="fs-title">Finish</h2>
                    <br /> */}
                    <h2 className="purple-text text-center">
                      <strong>SUCCESS!</strong>
                    </h2>
                    <br />
                    <div className="row justify-content-center">
                      <div className="col-3">
                        <img
                          src="https://i.imgur.com/GwStPmg.png"
                          className="fit-image"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                      <div className="col-7 text-center">
                        <h5>You Have Successfully Filled Up</h5>
                      </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                      <div className="col-6 text-center">
                        <p>
                          Please check your email for further instructions on
                          how to complete your admission process.
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setCurrentStep(1)}
                    >
                      Reset
                    </button>
                  </div>
                </fieldset>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MultiStepForm;
