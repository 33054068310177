import React, { useState, useEffect } from "react";
import axios from "axios";
import Common_URL from "../../Config";
const UpcomingNews = () => {
  const [facilities, setFacilities] = useState([]);
  const [showUpdateFacilitiesModal, setShowUpdateFacilitiesModal] =
    useState(false);
  const [updatedFacilitiesSubHeading, setUpdatedFacilitiesSubHeading] =
    useState("");
  const [updatedFacilitiesHeading, setUpdatedFacilitiesHeading] = useState("");
  const [updatedFacilitiesContent, setUpdatedFacilitiesContent] = useState("");
  const [updatedUpcomingStartDate, setUpdatedUpcomingStartDate] = useState("");
  const [selectedUpcomingNewsId, setselectedUpcomingNewsId] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(
          Common_URL + "&contenttype=upcoming%20news"
        );
        setFacilities(response.data);
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    fetchFacilities();
  }, []);

  const handleUpcomingNews = async (
    upcomingNewsId,
    newHeading,
    newSubHeading,
    newContent,
    newActive,
    newStartDate
  ) => {
    try {
      const formData = new FormData();
      formData.append("heading", newHeading);
      formData.append("subheading", newSubHeading);
      formData.append("content", newContent);
      formData.append("active", newActive);
      formData.append("startdate", newStartDate);
      // console.log(newSubHeading);
      await axios.put(
        `https://vjeducation.co.in/webapi/contentlist/${upcomingNewsId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update the facilities state with the updated data
      const updatedFacilities = facilities.map((facilitiess) => {
        if (facilitiess.id === upcomingNewsId) {
          return {
            ...facilitiess,
            heading: newHeading,
            subheading: newSubHeading,
            content: newContent,
            active: newActive,
            startdate: newStartDate,
          };
        } else {
          return facilitiess;
        }
      });
      setFacilities(updatedFacilities);

      setShowSuccessToast(true); // Show success toast
      setShowUpdateFacilitiesModal(false); // Close the update modal after successful update
    } catch (error) {
      console.error("Error updating PDF:", error);
      setShowErrorToast(true); // Show error toast
    }
  };

  const handleDeletePdf = async (upcomingNewsId) => {
    if (window.confirm(`Are you sure you want to delete ?`)) {
      try {
        await axios.delete(
          `https://vjeducation.co.in/webapi/contentlist/${upcomingNewsId}`
        );

        const filteredFacilities = facilities.filter(
          (facilitiess) => facilitiess.id !== upcomingNewsId
        );
        setFacilities(filteredFacilities);

        setShowSuccessToast(true); // Show success toast
      } catch (error) {
        console.error("Error deleting PDF:", error);
        setShowErrorToast(true); // Show error toast
      }
    }
  };
  const handleRefresh = () => {
    fetch(Common_URL + "&contenttype=upcoming%20news")
      .then((response) => response.json())
      .then((data) => {
        setFacilities(data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  useEffect(() => {
    if (showSuccessToast || showErrorToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
        setShowErrorToast(false);
      }, 3000); // Hide the toast after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast, showErrorToast]);

  const baseUrl = "https://vjeducation.co.in/webapi";

  return (
    <div>
      <div className="container">
        <div className="row my-3">
          <div className="col-md-2">
            <button className="btn btn-info btn-sm" onClick={handleRefresh}>
              Refresh
            </button>
          </div>
        </div>
        {facilities.map((facility) => (
          <div key={facility.id}>
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div
                    className={`col-md-8 order-md-${facility.active ? 1 : 2}`}
                  >
                    <h5>
                      {facility.heading} (
                      <span className="m-3 text-danger">
                        {facility.startdate}
                      </span>
                      ~
                      <span className="m-3 text-danger">
                        {facility.enddate}
                      </span>
                      )
                    </h5>

                    <h6 className="ml-2">{facility.subheading}</h6>
                    <p className="ml-2">{facility.content}</p>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="btn-group">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#upcomingBackdrop"
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      setselectedUpcomingNewsId(facility.id);
                      setUpdatedFacilitiesHeading(facility.heading);
                      setUpdatedFacilitiesSubHeading(facility.subheading);
                      setUpdatedFacilitiesContent(facility.content);
                      setUpdatedUpcomingStartDate(facility.startdate);
                      setShowUpdateFacilitiesModal(true);
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeletePdf(facility.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        ))}

        {/* Update PDF Modal */}
        <div
          id="upcomingBackdrop"
          className={`modal fade ${showUpdateFacilitiesModal ? "show" : ""}`}
          style={{ display: showUpdateFacilitiesModal ? "block" : "none" }}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Facilities</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowUpdateFacilitiesModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label
                    htmlFor="updatedFacilitiesHeading"
                    className="form-label"
                  >
                    New Heading
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="updatedFacilitiesHeading"
                    value={updatedFacilitiesHeading}
                    onChange={(e) =>
                      setUpdatedFacilitiesHeading(e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="updatedFacilitiesSubHeading"
                    className="form-label"
                  >
                    New Sub Heading
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="updatedFacilitiesSubHeading"
                    value={updatedFacilitiesSubHeading}
                    onChange={(e) =>
                      setUpdatedFacilitiesSubHeading(e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="updatedFacilitiesContent"
                    className="form-label"
                  >
                    New Content
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="updatedFacilitiesContent"
                    value={updatedFacilitiesContent}
                    onChange={(e) =>
                      setUpdatedFacilitiesContent(e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="updatedUpcomingStartDate"
                    className="form-label"
                  >
                    New Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="updatedUpcomingStartDate"
                    value={updatedUpcomingStartDate}
                    onChange={(e) =>
                      setUpdatedUpcomingStartDate(e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button
                  class="btn btn-danger"
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={() => setShowUpdateFacilitiesModal(false)}
                >
                  Cancle
                </button>

                {/* <button
                type="button"
                className="btn btn-success"
                onClick={handleUpcomingNews}
              >
                Save changes
              </button> */}

                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    handleUpcomingNews(
                      selectedUpcomingNewsId,
                      updatedFacilitiesHeading,
                      updatedFacilitiesSubHeading,
                      updatedFacilitiesContent,
                      updatedUpcomingStartDate
                    )
                  }
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bootstrap Toasts */}
        {/* Success Toast */}

        <div
          aria-live="polite"
          aria-atomic="true"
          class="d-flex justify-content-center align-items-center w-100"
        >
          <div
            id="liveToast"
            className={`toast bg-success ${showSuccessToast ? "show" : "hide"}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <strong className="me-auto">Submission Status</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setShowSuccessToast(false)}
              ></button>
            </div>
            <div className="toast-body text-white">Operation successful</div>
          </div>
        </div>

        {/* Error Toast */}
        <div
          className={`toast align-items-center text-white bg-danger ${
            showErrorToast ? "show" : ""
          }`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: 9999,
          }}
        >
          <div className="d-flex">
            <div className="toast-body">Error updating/deleting PDF</div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setShowErrorToast(false)}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingNews;
