import React, { useState, useEffect } from "react";
import Common_URL from "../../Config";
const GalleryDelete = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch(Common_URL + "&contenttype=gallery")
      .then((response) => response.json())
      .then((data) => {
        setImages(data); // Assuming the response is an array of image objects
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const handleDelete = (imageId) => {
    // Open an alert to confirm the deletion
    if (
      window.confirm(
        `Are you sure you want to delete the image with id:${imageId} ?`
      )
    ) {
      fetch(`https://vjeducation.co.in/webapi/contentlist/${imageId}`, {
        method: "DELETE",
      })
        .then((response) => {
          // Handle success response
          // Remove the deleted image from the state
          setImages((prevImages) =>
            prevImages.filter((image) => image.id !== imageId)
          );
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
    }
  };

  const handleRefresh = () => {
    fetch(Common_URL + "&contenttype=gallery")
      .then((response) => response.json())
      .then((data) => {
        setImages(data); // Update the images state with the refreshed data
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const baseUrl = "https://vjeducation.co.in/webapi";
  return (
    <>
      <h2 className="admin_heading">Gallery</h2>
      <hr />
      <div className="row my-3">
        <div className="col">
          <button className="btn btn-info btn-sm" onClick={handleRefresh}>
            Refresh
          </button>
        </div>
      </div>
      <div className="row admin_gallery_img">
        {images.map((image) => (
          <div className="col-lg-2 col-md-3 col-6 text-center">
            <div key={image.id}>
              <img
                className="img-fluid"
                src={baseUrl + image.imageurl}
                alt={image.title}
              />
              <h6 className="mb-4">ID:{image.id}</h6>
              <button
                className="btn btn-sm btn-danger my-2"
                onClick={() => handleDelete(image.id)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default GalleryDelete;
