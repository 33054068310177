import React from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import Mpd from "./Admin/Mpd.js";

const MandatoryPublicDisclosure = () => {
  return (
    <>
      <Helmet>
        <CommonSection title="Mandatory Public Disclosure" className="mb_30" />
        <section id="main_news_layout">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <section class="set_new_height">
                  <div className="">
                    <Mpd />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </Helmet>
    </>
  );
};

export default MandatoryPublicDisclosure;
