import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";


import driveImg from "../assets/all-images/drive.jpg";
import OurToppers from "../components/UI/OurToppers";
import "../styles/about.css";

const About = () => {
  return (
    <>
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            {/* <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col> */}

            <Col lg="12" md="12" sm="12">
              <div className="about__page-content">

                <div class="solar_our_vison_detail">
                  <h5 class="solar_center solar_left">What make us different</h5>
                  <h1 class="text_span">Why choose us</h1>
                  <p>
                  Great schools have many distinguishing features in common.  Some exhibit a number of uncommon features as well.  This post is not intended to be an exhaustive report on all the characteristics of great schools.  It lists, however, 9 features that most educational experts and practitioners consider to be necessary in the anatomy of any great school.  I present them here in no particular order.

                  </p>
                  

                   
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>01</span>
                      </div>
                      <div class="solar_why_choose_text">
                      
                        <p>


                        A very clear and shared mission and vision for the school.  A contextual philosophy of education for the school is also paramount.  Staff, students, parents and the community need to know the purpose and operational parameters of the school.  Nothing is left to chance.
                       
                       
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>02</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        Very clear goals and objectives which are communicated to the staff, students, parents and wider community.  Everyone in the school knows exactly what he or she is required to do on a daily basis in order to meet the institutional goals and objectives.  This speaks to performance on every level.
                       
                       
                        </p>
                      </div>
                    </div>
                  </div>





                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>03</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        Great leadership from the principal.  The principal is both instructional leader and manager of the school.  While both these roles are important, he or she must pay particular attention to instructional leadership.  This is what drives continual improvement in teaching and  learning throughout the school.
                       
                        </p>
                      </div>
                    </div>
                  </div>





                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>04</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        A relentless focus on teaching and learning.  This is informed by the belief that all students can learn.  Teachers must find ways to motivate and engage them.  The curriculum is rigorous and teacher performance is regularly monitored by the principal and executive staff.  Teachers and students strive for excellence in academic and extracurricular activities.  Good performance is always publicly recognized and rewarded in various ways.
                       
                        </p>
                      </div>
                    </div>
                  </div>




                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>05</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        Parental involvement in the school.  Many studies have shown that students learn better when their parents take a close interest in their schooling and form partnerships with teachers.  Parents can also help in various school activities.  Alumni and the wider community can also offer assistance in many ways.
                       
                        </p>
                      </div>
                    </div>
                  </div>




                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>06</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        High expectations for students and staff.  It is common knowledge that students will generally live up to your expectations.  When they know that teachers genuinely care about them and believe in their ability, they work harder and perform better.
                       
                        </p>
                      </div>
                    </div>
                  </div>

                


                  {/* <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>07</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>
                        A culture and climate which are conducive to learning.  This is reinforced by praise and rewards for good performance and a student-centred approach to teaching.
                        
                        
                        
                        </p>
                      </div>
                    </div>
                  </div> */}





                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>07</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>
                        A safe environment.  Zero tolerance for violence, bullying, drugs, alcohol, offensive weapons, stealing, sexual misconduct, and gangs.  Heavy emphasis on positive values such as respect, honesty, hard work, self-discipline, fairness and caring.
                        </p>
                      </div>
                    </div>
                  </div>



                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>08</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        A focus on professional development for teachers.  This includes mentoring, delegation of tasks and additional responsibilities and formal in-house or external professional development courses.  Teachers are accountable for their performance.
                        </p>
                      </div>
                    </div>
                  </div>




                  
                  <div class="col-lg-12 col-md-12">
                    <div class="solar_vison_detail mt-0">
                      <div class="solar_why_choose_icon">
                      <span>09</span>
                      </div>
                      <div class="solar_why_choose_text">
                       
                        <p>

                        Teamwork.  Identification and collaborative solving of teaching and learning problems at the school.  Staff members are empowered to take the initiative and make decisions.  The principal facilitates and monitors this process. ,
                        
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <p>

                One constant among the features of a great school is that good ideas and best practices are shared among the staff.  When decisions have to be made, the first considerations revolve around what is best for the students and the school.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      

      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurToppers />
          </Row>
        </Container>
      </section> */}
    </Helmet>
    </>
  );
};

export default About;
