import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/about-section.css";
import fac from "../assets/illustration/fac.png";
import side from "../assets/illustration/side.png";
import Common_URL from ".././Config";
const CarListing = () => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          Common_URL + "&contenttype=facilities"
        );
        setContents(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  const renderContents = () => {
    const baseUrl = "https://vjeducation.co.in/webapi";

    return contents.map((content, index) => (
      <div key={index} class="row  sdsdsds">
        <div className={`col-md-7 setcss  order-md-${content.active ? 1 : 2}`}>
          <div class="solar_about_detail">
            <h5 class="solar_center solar_left">{content.heading}</h5>
            <h6>{content.subheading}</h6>
            <p>{content.content}</p>
          </div>
        </div>
        <div className={`col-md-5 order-md-${content.active ? 2 : 1}`}>
          <div class="about_rotate">
            <img
              className="classroom"
              src={baseUrl + content.imageurl}
              alt="..........."
            />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Helmet title="Cars">
      <CommonSection title="Facilities" />

      <section className="about__section">
        <div class="container">
          <div className="facilities_blocks">
            <div class="row  sdsdsds">
              <div class="col-lg-12 col-md-12 setcss">
                <div class="solar_about_detail">
                  <h5 class="solar_center solar_left">We are providing</h5>
                  <h1 class="text_span">
                    Planning to enroll your child in a good school? Consider
                    these factors for wise decision!
                  </h1>
                  <p>
                    Our commitment to excellence extends to the facilities we
                    provide on campus. The infrastructure, sports arenas, music
                    room, transport arrangements, library, laboratories, medical
                    facilities are all designed to be a part of the green
                    surroundings. TAS is a well-designed campus which offers all
                    the modern facilities that are needed to provide the
                    students a safe, secure and happy learning environment.
                  </p>
                  <h6>
                    <b>
                      Here is a detailed list of basic school facilities that
                      provided by us:-
                    </b>
                  </h6>

                  {/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
                </div>
              </div>
              {/* <div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={fac} alt="about-us" />

						
						</div>						
					</div>										 */}
            </div>
            <div className="container">{renderContents()}</div>
          </div>
        </div>
      </section>
    </Helmet>
  );
};

export default CarListing;
