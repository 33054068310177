import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import girls from "../../assets/illustration/girls.jpg";
import director from "../../assets/illustration/director.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const CulturalEvents = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="">
      <div className="testimonial">
        <div className="gap_4">
          <img src={girls} alt="" className="img_culture" />
        </div>
      </div>

      <div className="testimonial">
        <div className="gap_4">
          <img src={girls} alt="" className="img_culture" />
        </div>
      </div>

      <div className="testimonial">
        <div className="gap_4">
          <img src={girls} alt="" className="img_culture" />
        </div>
      </div>

      <div className="testimonial">
        <div className="gap_4">
          <img src={girls} alt="" className="img_culture" />
        </div>
      </div>

      <div className="testimonial">
        <div className="gap_4">
          <img src={girls} alt="" className="img_culture" />
        </div>
      </div>
    </Slider>
  );
};

export default CulturalEvents;
