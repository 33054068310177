import axios from 'axios';
import React, { useEffect,useState } from 'react'
import NewsTicker, { Directions, TickerStates } from "react-advanced-news-ticker";

function NewsTickers() {


  const ref = React.useRef();
  return (
    <>
        <div class="d-flex">
        <div class="pr-3">
        <i className="fas fa-long-arrow-alt-left mt-2"
            id="nt-example2-prev"
            onClick={() => { ref.current.movePrev(); ref.current.resetInterval(); }} />
            
         
        </div>
    <NewsTicker
    ref={ref} 
    id="nt-example1" 
    rowHeight = {24}
    maxRows = {1}
    speed = {600}
     direction = {Directions.UP}
     duration = {4000}
     autoStart = {true}
     pauseOnHover = {true}
     className = "myClassName1 myClassName2"
     style = {{color: "#fff" }}
       
      >
       {/* { data.map((e,i)=><div>“Learn as much as you can while you are young, since life becomes too busy later.</div>)} */}

         {/* <div>{data[0].displayDesc}</div>  */}
       
    <div>“Learn as much as you can while you are young, since life becomes too busy later.”</div>
    <div>“Success is the sum of small efforts, repeated day in and day out.”</div>
    <div>“School is a building which has four walls with tomorrow inside.” </div>
    <div>“Education is the key to unlocking the world, a passport to freedom.”</div>
    </NewsTicker>

    <div>
    <i className="fas fa-long-arrow-alt-right ml-4 mt-2" 
            id="nt-example2-next" 
            onClick={() => { ref.current.moveNext(); ref.current.resetInterval(); }} />
    </div>
        </div>
   </>
  )
}

export default NewsTickers