// "https://api.aisbiharsharif.co.in/gallerylist/"

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Common_URL from ".././Config";
class NewsBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      image: "",
      heading: "",
      detail: "",
    };
  }

  componentDidMount() {
    // #1. First of all you have to fetch the images.newsandupdate
    fetch(Common_URL + "&contenttype=event")
      .then((response) => response.json()) // If it's a JSON response, you have to parse it firstly
      .then((images) => this.setState({ images })); // #2. After that you have to keep the images in the component's state.
  }

  render() {
    const { images } = this.state;
    const baseUrl = "https://vjeducation.co.in/webapi";

    if (!images) return <div>Images are not fetched yet!</div>;

    return (
      <>
        <div class="new_carousel_main">
          <Carousel
            infiniteLoop={true}
            showThumbs={false}
            showIndicators={false}
            interval="3000"
            autoPlay={true}
            stopOnHover={true}
            showStatus={false}
          >
            {images.map((imagesrc) => {
              return (
                <>
                  <div>
                    <img src={baseUrl + imagesrc.imageurl} alt="" />
                    <p className="legend">{imagesrc.heading}</p>
                  </div>
                  <div>
                    <h6 className="py-2 text-success text-left">
                      {imagesrc.startdate}
                    </h6>

                    <h5 className="text-left">{imagesrc.heading}</h5>
                    <p className="text-left">{imagesrc.content}</p>
                  </div>
                </>
              );
            })}
          </Carousel>
        </div>
      </>
    );
  }
}
export default NewsBanner;
