import React, { useState, useEffect } from "react";
import axios from "axios";
import Common_URL from "../../Config";
const FacilitiesConfig = () => {
  const [facilities, setFacilities] = useState([]);
  const [showUpdateFacilitiesModal, setShowUpdateFacilitiesModal] =
    useState(false);
  const [updatedFacilitiesSubHeading, setUpdatedFacilitiesSubHeading] =
    useState("");
  const [updatedFacilitiesHeading, setUpdatedFacilitiesHeading] = useState("");
  const [updatedFacilitiesActive, setUpdatedFacilitiesActive] = useState(false);
  const [updatedFacilitiesContent, setUpdatedFacilitiesContent] = useState("");
  const [updatedFacilitiesFile, setUpdatedFacilitiesFile] = useState(null);
  const [selectedfacilitiesId, setSelectedfacilitiesId] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(
          Common_URL + "&contenttype=facilities"
        );
        setFacilities(response.data);
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    fetchFacilities();
  }, []);

  const handleUpdatedFacilitiesFile = async (
    facilitiesId,
    newHeading,
    newSubHeading,
    newContent,
    newActive,
    newImageFile
  ) => {
    try {
      console.log(newActive);
      const formData = new FormData();
      formData.append("heading", newHeading);
      formData.append("subheading", newSubHeading);
      formData.append("content", newContent);
      formData.append("active", newActive);
      formData.append("imageurl", newImageFile);

      await axios.put(
        `https://vjeducation.co.in/webapi/contentlist/${facilitiesId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update the facilities state with the updated data
      const updatedFacilities = facilities.map((facilitiess) => {
        if (facilitiess.id === facilitiesId) {
          return {
            ...facilitiess,
            heading: newHeading,
            subheading: newSubHeading,
            content: newContent,
            active: newActive,
            imageurl: newImageFile,
          };
        } else {
          return facilitiess;
        }
      });
      setFacilities(updatedFacilities);

      setShowSuccessToast(true); // Show success toast
      setShowUpdateFacilitiesModal(false); // Close the update modal after successful update
    } catch (error) {
      console.error("Error updating image:", error);
      setShowErrorToast(true); // Show error toast
    }
  };

  const handleDeletePdf = async (facilitiesId) => {
    if (window.confirm(`Are you sure you want to delete ?`)) {
      try {
        await axios.delete(
          `https://vjeducation.co.in/webapi/contentlist/${facilitiesId}`
        );

        const filteredFacilities = facilities.filter(
          (facilitiess) => facilitiess.id !== facilitiesId
        );
        setFacilities(filteredFacilities);

        setShowSuccessToast(true); // Show success toast
      } catch (error) {
        console.error("Error deleting PDF:", error);
        setShowErrorToast(true); // Show error toast
      }
    }
  };
  const handleRefresh = () => {
    fetch(Common_URL + "&contenttype=facilities")
      .then((response) => response.json())
      .then((data) => {
        setFacilities(data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  useEffect(() => {
    if (showSuccessToast || showErrorToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
        setShowErrorToast(false);
      }, 3000); // Hide the toast after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast, showErrorToast]);

  const baseUrl = "https://vjeducation.co.in/webapi";

  return (
    <div>
      <div className="row my-3">
        <div className="col">
          <button className="btn btn-info btn-sm" onClick={handleRefresh}>
            Refresh
          </button>
        </div>
      </div>
      {facilities.map((facility) => (
        <div key={facility.id}>
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div
                  className={`col-md-4 setcss  order-md-${
                    facility.active ? 2 : 1
                  }`}
                >
                  <img
                    src={baseUrl + facility.imageurl}
                    alt="facilities image"
                    className="img-fluid"
                    width="150"
                    height="150"
                  />
                </div>
                <div className={`col-md-8 order-md-${facility.active ? 1 : 2}`}>
                  <h5>{facility.heading}</h5>{" "}
                  <span className="ml-2">
                    Content : {facility.active ? "Left" : "Right"}
                  </span>
                  <h6 className="ml-2">{facility.subheading}</h6>
                  <p className="ml-2">{facility.content}</p>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="btn-group">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropAboutUs"
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setSelectedfacilitiesId(facility.id);
                    setUpdatedFacilitiesHeading(facility.heading);
                    setUpdatedFacilitiesSubHeading(facility.subheading);
                    setUpdatedFacilitiesContent(facility.content);
                    setUpdatedFacilitiesActive(facility.active);
                    setUpdatedFacilitiesFile(facility.imageurl);
                    setShowUpdateFacilitiesModal(true);
                  }}
                >
                  Update
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeletePdf(facility.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      ))}

      {/* Update PDF Modal */}
      <div
        id="staticBackdropAboutUs"
        className={`modal fade ${showUpdateFacilitiesModal ? "show" : ""}`}
        style={{ display: showUpdateFacilitiesModal ? "block" : "none" }}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Facilities</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowUpdateFacilitiesModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label
                  htmlFor="updatedFacilitiesHeading"
                  className="form-label"
                >
                  New Heading
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="updatedFacilitiesHeading"
                  value={updatedFacilitiesHeading}
                  onChange={(e) => setUpdatedFacilitiesHeading(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="updatedFacilitiesSubHeading"
                  className="form-label"
                >
                  New Sub Heading
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="updatedFacilitiesSubHeading"
                  value={updatedFacilitiesSubHeading}
                  onChange={(e) =>
                    setUpdatedFacilitiesSubHeading(e.target.value)
                  }
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="updatedFacilitiesContent"
                  className="form-label"
                >
                  New Content
                </label>
                <textarea
                  rows={5}
                  type="text"
                  className="form-control"
                  id="updatedFacilitiesContent"
                  value={updatedFacilitiesContent}
                  onChange={(e) => setUpdatedFacilitiesContent(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="updatedFacilitiesFile" className="form-label">
                  New Image File
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="updatedFacilitiesFile"
                  onChange={(e) => setUpdatedFacilitiesFile(e.target.files[0])}
                />
              </div>

              <div class="form-group horizontal-checkbox ">
                <input
                  className="mr-3"
                  type="checkbox"
                  id="updatedFacilitiesActive"
                  checked={updatedFacilitiesActive}
                  onChange={(e) => setUpdatedFacilitiesActive(e.target.checked)}
                />
                <label class="ml-3 control-label ">Image Position Left</label>
              </div>
            </div>

            <div className="modal-footer">
              <button
                class="btn btn-danger"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={() => setShowUpdateFacilitiesModal(false)}
              >
                Cancle
              </button>

              {/* <button
                type="button"
                className="btn btn-success"
                onClick={handleUpdatedFacilitiesFile}
              >
                Save changes
              </button> */}

              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  handleUpdatedFacilitiesFile(
                    selectedfacilitiesId,
                    updatedFacilitiesHeading,
                    updatedFacilitiesSubHeading,
                    updatedFacilitiesContent,
                    updatedFacilitiesActive,
                    updatedFacilitiesFile
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Toasts */}
      {/* Success Toast */}

      <div
        aria-live="polite"
        aria-atomic="true"
        class="d-flex justify-content-center align-items-center w-100"
      >
        <div
          id="liveToast"
          className={`toast bg-success ${showSuccessToast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Submission Status</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setShowSuccessToast(false)}
            ></button>
          </div>
          <div className="toast-body text-white">Operation successful</div>
        </div>
      </div>

      {/* Error Toast */}
      <div
        className={`toast align-items-center text-white bg-danger ${
          showErrorToast ? "show" : ""
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ position: "fixed", top: "10px", right: "10px", zIndex: 9999 }}
      >
        <div className="d-flex">
          <div className="toast-body">Error updating/deleting PDF</div>
          <button
            type="button"
            className="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={() => setShowErrorToast(false)}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesConfig;
