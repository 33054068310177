import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/all-images/school_img/logo.png";
import "../../styles/header.css";
import axios from "axios";
import NewsTickers from "./NewsTicker.jsx";
import Common_URL from "../.././Config";
const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About Us",
  },
  {
    path: "/facilities",
    display: "Facilities",
  },
  {
    path: "/admission",
    display: "Admission",
  },
  {
    path: "/gallery",
    display: "Gallery",
  },
  {
    path: "/newsevents",
    display: "News & Events",
  },
  {
    path: "/contact",
    display: "Contact",
  },
  {
    path: "/MandatoryPublicDisclosure",
    display: "Mandatory Public Disclosure",
  },
];

const Header = () => {
  const [navClass, setNavClass] = useState("");
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const facebookResponse = await axios.get(
          Common_URL + "&contenttype=facebook"
        );
        const twitterResponse = await axios.get(
          Common_URL + "&contenttype=twitter"
        );
        const linkedinResponse = await axios.get(
          Common_URL + "&contenttype=linkedin"
        );
        const youtubeResponse = await axios.get(
          Common_URL + "&contenttype=youtube"
        );
        const whatsappResponse = await axios.get(
          Common_URL + "&contenttype=whatsapp"
        );

        setContents([
          { platform: "Facebook", data: facebookResponse.data[0].contenturl },
          { platform: "Twitter", data: twitterResponse.data[0].contenturl },
          { platform: "Linkedin", data: linkedinResponse.data[0].contenturl },
          { platform: "Youtube", data: youtubeResponse.data[0].contenturl },
          { platform: "Whatsapp", data: whatsappResponse.data[0].contenturl },
        ]);
        console.log(linkedinResponse.data); // Check the structure and find the correct property
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  const renderContents = () => {
    return contents.map((item, index) => (
      <li key={index}>
        <Link to={item.data} className="social__link-icon" target="_blank">
          <i className={`fab fa-${item.platform.toLowerCase()}`}></i>
        </Link>
      </li>
    ));
  };
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);

  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          Common_URL + "&contenttype=upcoming%20news"
        );
        const data = await response.json();
        setContentList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <div className="container-fluid">
          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="topbar">
                <div className="d-flex align-item-center justify-content-between">
                  <div className="header__top__left col-md-9">
                    <marquee
                      class="news-scroll"
                      behavior="scroll"
                      direction="left"
                      onMouseEnter={() =>
                        document.querySelector(".news-scroll").stop()
                      }
                      onMouseLeave={() =>
                        document.querySelector(".news-scroll").start()
                      }
                    >
                      {contentList.map((content, index) => (
                        <span key={index} className="topbar_news">
                          <span class="dot"></span>{" "}
                          <span>{content.heading}... &nbsp;</span>
                          <Link to="/newsevents" className="text-danger">
                            {" "}
                            Read More
                          </Link>
                        </span>
                      ))}
                    </marquee>
                  </div>
                  <div className="header__top__right col-md-3 d-flex align-items-center justify-content-center gap-1">
                    <div className="header_social_icon">
                      <ul>{renderContents()}</ul>
                    </div>

                    <Link
                      to="/loginpage"
                      className="d-flex align-items-center gap-2 login_btn"
                    >
                      <i class="ri-login-circle-line"></i> Login
                    </Link>

                    {/* <Link to="#" className=" d-flex align-items-center gap-1">
                  <i class="ri-user-line"></i> Register
                </Link> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* ========== main navigation =========== */}

      <div className="main__navbars">
        <div className="container-fluid  px-lg-5 set_sm">
          <Row className="d-flex align-items-center">
            <Col lg="6" md="3" sm="4">
              <div className="logo">
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <img src={logo} alt="" className="img-fluid" />{" "}
                </Link>
              </div>
            </Col>
            <Col lg="6" md="3" sm="4" className="hide_sm">
              <div className=" d-flex justify-content-end">
                <div>
                  <span className="header__top__help mr-4">
                    <i class="ri-mail-fill"></i> gippublicschoolpkb@gmail.com
                    &nbsp; &nbsp;
                  </span>
                  <span className="header__top__help ml-5">
                    <i class="ri-phone-fill"></i> +91 7903220491
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            className={`d-flex align-items-center menu_row mt-2 main__navbar ${navClass}`}
          >
            <Col lg="12" md="12" sm="12">
              <div className="navigation__wrapper d-flex align-items-center justify-content-center">
                <span className="mobile__menu">
                  <i class="ri-menu-line" onClick={toggleMenu}></i>
                </span>

                <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                  <div className="menu">
                    {navLinks.map((item, index) => (
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive
                            ? "nav__active nav__item"
                            : "nav__item"
                        }
                        key={index}
                      >
                        {item.display}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </header>
  );
};

export default Header;
