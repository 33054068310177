import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/UpcomingNews.css";
import Common_URL from ".././Config";
function ContentSlider() {
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          Common_URL + "&contenttype=upcoming%20news"
        );
        const data = await response.json();
        setContentList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <div class="main_upcoming_news">
      <Slider {...settings}>
        {contentList.map((content, index) => (
          <ul class="upnews">
            {/* {item.status === 1 ? (
          <li key={item.id}>
            <h6>{item.events_heading}</h6>
            <span className="date_tag">{item.startdate}</span>
            <a>{item.events_url}</a>
            <p>{item.events_content}</p>
          </li>
        ) : null} */}

            <li key={index}>
              <h6>{content.heading}</h6>
              <span className="date_tag">{content.startdate}</span>
              <span>{content.contenturl}</span>
              <p>{content.content}</p>
            </li>
          </ul>
        ))}
      </Slider>
    </div>
  );
}

export default ContentSlider;
