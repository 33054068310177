// import React, { useState, useEffect } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";

// const AcademicCalendar = () => {
//   const [events, setEvents] = useState([]);
//   const [markedDates, setMarkedDates] = useState([]);

//   useEffect(() => {
//     fetch("https://api.example.com/startdate")
//       .then((response) => response.json())
//       .then((data) => {
//         const startDate = new Date(data.startdate);
//         const markedDate = {
//           date: startDate,
//           title: "Start Date",
//           style: { backgroundColor: "red" },
//         };
//         setMarkedDates([markedDate]);
//       })
//       .catch((error) => console.error("Error fetching start date:", error));
//   }, []);

//   const localizer = momentLocalizer(moment);

//   return (
//     <div className="container calender">
//       <Calendar
//         localizer={localizer}
//         events={events}
//         startAccessor="start"
//         endAccessor="end"
//         selectable
//         onSelectSlot={(slotInfo) => console.log("Selected slot:", slotInfo)}
//         dayPropGetter={(date) => {
//           const markedDate = markedDates.find((md) =>
//             moment(md.date).isSame(date, "day")
//           );
//           return markedDate ? { style: markedDate.style } : {};
//         }}
//       />
//     </div>
//   );
// };

// export default AcademicCalendar;

import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Common_URL from "../Config";
const localizer = momentLocalizer(moment);
const AcademicCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=clalender");
        const formattedEvents = response.data.map((event) => ({
          id: event.id,
          title: event.heading,
          start: new Date(event.startdate),
          end: new Date(event.enddate),
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container calender">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
};

export default AcademicCalendar;
