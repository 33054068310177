import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/about-section.css";
import about from "../../assets/all-images/about.png";
import Common_URL from "../../Config";
const AboutSection = ({ aboutClass }) => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=aboutus");
        setContents(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  const renderContents = () => {
    const baseUrl = "https://vjeducation.co.in/webapi";

    return contents.map((content, index) => (
      <div key={index} class="row mt-5">
        <div className={`col-md-7 setcss  order-md-${content.active ? 1 : 2}`}>
          <div class="solar_about_detail">
            <h5 class="solar_center solar_left">{content.heading}</h5>

            <h2 class="text_span">{content.subheading}</h2>
            <p>{content.content}</p>
            <Link to="/about" className="solar_btn my-4">
              View More
            </Link>
          </div>
        </div>
        <div className={`col-md-5 order-md-${content.active ? 2 : 1}`}>
          <div class="about_us_image">
            <img
              className="img_full"
              src={baseUrl + content.imageurl}
              alt="..........."
            />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <section
        className="about__section"
        style={
          aboutClass === "aboutPage"
            ? { marginTop: "0px" }
            : { marginTop: "-50px" }
        }
      >
        <div className="container">{renderContents()}</div>
      </section>
    </>
  );
};

export default AboutSection;
