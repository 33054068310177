import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Common_URL from "../../Config";
const HeroSlider = () => {
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Common_URL + "&contenttype=slider");
        console.log(response);

        const data = await response.json();
        console.log(data);
        setContentList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };
  const baseUrl = "https://vjeducation.co.in/webapi";

  return (
    <div>
      <h2>Content Slider</h2>
      <Slider {...settings}>
        {contentList.map((content, index) => (
          <div key={index}>
            <img src={baseUrl + content.imageurl} alt={content.heading} />

            <p>{content.heading}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
