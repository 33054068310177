import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf2 from "../../assets/illustration/pdf2.png"; // Import the PDF icon image
import Common_URL from "../../Config";
const FeeStrConfig = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updatedHeading, setUpdatedHeading] = useState("");
  const [updatedPdfFile, setUpdatedPdfFile] = useState(null);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const baseUrl = "https://vjeducation.co.in/webapi";

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          Common_URL + "&contenttype=feestructure"
        );
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDownloadPdf = (pdfUrl, heading) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.setAttribute("rel", "noopener noreferrer"); // For security
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(`No PDF available for "${heading}"`);
    }
  };

  const handleUpdatePdf = async (announcementId, newHeading, newPdfFile) => {
    try {
      const formData = new FormData();
      formData.append("heading", newHeading);
      formData.append("pdfFile", newPdfFile);
      console.log(announcementId);
      await axios.put(
        `https://vjeducation.co.in/webapi/contentlist/${announcementId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update the announcements state with the updated data
      const updatedAnnouncements = announcements.map((announcement) => {
        if (announcement.id === announcementId) {
          return {
            ...announcement,
            heading: newHeading,
            pdfurl: newPdfFile, // Assuming the updated PDF file URL is returned from the server
          };
        } else {
          return announcement;
        }
      });
      setAnnouncements(updatedAnnouncements);

      setShowSuccessToast(true); // Show success toast
      setShowUpdateModal(false); // Close the update modal after successful update
    } catch (error) {
      console.error("Error updating PDF:", error);
      setShowErrorToast(true); // Show error toast
    }
  };

  const handleDeletePdf = async (announcementId) => {
    if (window.confirm(`Are you sure you want to delete ?`)) {
      try {
        await axios.delete(
          `https://vjeducation.co.in/webapi/contentlist/${announcementId}`
        );

        const filteredAnnouncements = announcements.filter(
          (announcement) => announcement.id !== announcementId
        );
        setAnnouncements(filteredAnnouncements);

        setShowSuccessToast(true); // Show success toast
      } catch (error) {
        console.error("Error deleting PDF:", error);
        setShowErrorToast(true); // Show error toast
      }
    }
  };
  const handleRefresh = () => {
    fetch(Common_URL + "&contenttype=feestructure")
      .then((response) => response.json())
      .then((data) => {
        setAnnouncements(data); // Update the images state with the refreshed data
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  useEffect(() => {
    if (showSuccessToast || showErrorToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
        setShowErrorToast(false);
      }, 3000); // Hide the toast after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast, showErrorToast]);

  const getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const getSessionText = (startDate, endDate) => {
    if (startDate && endDate) {
      return `${getYearFromDate(startDate)} - ${getYearFromDate(endDate)}`;
    } else {
      return "Session Invalid";
    }
  };
  return (
    <div className="container-fluid px-lg-5">
      <div className="row my-3">
        <div className="col">
          <button className="btn btn-info btn-sm" onClick={handleRefresh}>
            Refresh
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Fee Str.</th>
            <th scope="col">Class</th>
            <th scope="col">Session</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {announcements.map((announcement) => (
            <tr key={announcement.id}>
              <th scope="row">
                <img src={pdf2} alt="PDF" width="45" height="40" />
              </th>
              <td>{announcement.heading}</td>
              <td>
                {getSessionText(announcement.startdate, announcement.enddate)}
              </td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={() =>
                      handleDownloadPdf(
                        baseUrl + announcement.pdfurl,
                        announcement.heading
                      )
                    }
                  >
                    Download
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropFeeStr"
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      setSelectedAnnouncementId(announcement.id);
                      setUpdatedHeading(announcement.heading);
                      setShowUpdateModal(true);
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeletePdf(announcement.id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Update PDF Modal */}
      <div
        id="staticBackdropFeeStr"
        className={`modal fade ${showUpdateModal ? "show" : ""}`}
        style={{ display: showUpdateModal ? "block" : "none" }}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update PDF</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowUpdateModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="updatedHeading" className="form-label">
                  New Heading
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="updatedHeading"
                  value={updatedHeading}
                  onChange={(e) => setUpdatedHeading(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="updatedPdfFile" className="form-label">
                  New PDF File
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="updatedPdfFile"
                  onChange={(e) => setUpdatedPdfFile(e.target.files[0])}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                class="btn btn-danger"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={() => setShowUpdateModal(false)}
              >
                Cancle
              </button>

              {/* <button
                type="button"
                className="btn btn-success"
                onClick={handleUpdatePdf}
              >
                Save changes
              </button> */}

              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  handleUpdatePdf(
                    selectedAnnouncementId,
                    updatedHeading,
                    updatedPdfFile
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Toasts */}
      {/* Success Toast */}

      <div
        aria-live="polite"
        aria-atomic="true"
        class="d-flex justify-content-center align-items-center w-100"
      >
        <div
          id="liveToast"
          className={`toast bg-success ${showSuccessToast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Submission Status</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setShowSuccessToast(false)}
            ></button>
          </div>
          <div className="toast-body text-white">Operation successful</div>
        </div>
      </div>

      {/* Error Toast */}
      <div
        className={`toast align-items-center text-white bg-danger ${
          showErrorToast ? "show" : ""
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ position: "fixed", top: "10px", right: "10px", zIndex: 9999 }}
      >
        <div className="d-flex">
          <div className="toast-body">Error updating/deleting PDF</div>
          <button
            type="button"
            className="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={() => setShowErrorToast(false)}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default FeeStrConfig;
