import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import girls from "../../assets/illustration/girls.jpg";
import director from "../../assets/illustration/director.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-md-8 testimonial_col">
            <img
              className="quote_right"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVR4nO2YMS8FQRDHp6BVKRRCQqnVkSh0CoXkzT4kRKPwAWgUKjQKGo3KF5AolRKJQlQiIWHnIhISFXZPSKxcpXlmDy8vby//X3L1b3I7OzszRAAAAAAAAIBGeDGhmV+7+aLgBwgyIOAKCGpAQBEUvAIBz6CgDwhohASdYEArLJgFAoYhwTQYMA4L9gEBCxHBRihgJSYV3gk6a6ac8FUzgnFi3trNV4oQxjp8xnNe+PR/QfFjO/p+RZ7NDDjLS87yvhc+98IPTjgvdyJ82e6+P+OFR0oEdZCqL4q7m+0tcSfXKVFflFdb64kF9Gp5ghL1RXnJ6kPqaVjzEa5nuyhRXxSX1Sf1gPiIEvZFccIbWkC55QVK2BeleHKUYvTc7HRstU/FZfVhtSBZ3qaEfVG8NTtaMcozM0gJ+1Se76e7i5RTitEeJeyL4oS3lNNw/qbWRwn7VPztTL+zxv94FzOzQgn7onhrDpVCdBHOFjspYZ+Kt1xXUvHTZ7VRStgXH0SseVIK0SYl7FMp0sxZPlaCOSkWGpSoL4oXs/vzPTRPza7CrfZFKSpt4/aT33Mx45S47+9B3dbmqSK+Ujhr1r4D4mWqmO8XQfEqVdQHAAAAAKo0X62c7J5gVJYAAAAAAElFTkSuQmCC"
            />
            <Slider {...settings}>
              <div className="testimonial py-4 px-3">
                <p className="section__description">
                  “My Daughter is always happy to go to school and full of
                  smiles when we pick her up. I attribute this to the exemplary
                  teaching and community feel of the school. Any overseas move
                  following hard to leave such a great and caring school.”
                </p>

                <div>
                  <h6 className="mb-0 mt-3">Parent's Name</h6>
                  <p className="section__description">Parent's City Name</p>
                </div>
              </div>

              <div className="testimonial py-4 px-3">
                <p className="section__description">
                  “Fantastic school! The teachers are Professional, caring and
                  well organized. The admissions process was outstanding, they
                  really care and truly want the best for your child.”
                  <br></br>Overall Very Good School
                </p>

                <div>
                  <h6 className="mb-0 mt-3">Parent's Name</h6>
                  <p className="section__description">Parent's City Name</p>
                </div>
              </div>

              <div className="testimonial py-4 px-3">
                <p className="section__description">
                  “Every day my children are looking forward to coming to
                  school. They participate in a variety of activities and events
                  and enjoy both their teachers and classmates company. They are
                  having a great time and lots of fun too!”
                </p>

                <div>
                  <h6 className="mb-0 mt-3">Parent's Name</h6>
                  <p className="section__description">Parent's City Name</p>
                </div>
              </div>

              <div className="testimonial py-4 px-3">
                <p className="section__description">
                  “My Daughter is always happy to go to school and full of
                  smiles when we pick her up. I attribute this to the exemplary
                  teaching and community feel of the school. Any overseas move
                  following hard to leave such a great and caring school.”
                </p>

                <div>
                  <h6 className="mb-0 mt-3">Parent's Name</h6>
                  <p className="section__description">Parent's City Name</p>
                </div>
              </div>

              <div className="testimonial py-4 px-3">
                <p className="section__description">
                  “Fantastic school! The teachers are Professional, caring and
                  well organized. The admissions process was outstanding, they
                  really care and truly want the best for your child.”
                  <br></br>Overall Very Good School
                </p>

                <div>
                  <h6 className="mb-0 mt-3">Parent's Name</h6>
                  <p className="section__description">Parent's City Name</p>
                </div>
              </div>
            </Slider>

            <img
              className="quote_left"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVR4nO2YMS8FQRDHp6BVKRRCQqnVkSh0CoXkzT4kRKPwAWgUKjQKGo3KF5AolRKJQlQiIWHnIhISFXZPSKxcpXlmDy8vby//X3L1b3I7OzszRAAAAAAAAIBGeDGhmV+7+aLgBwgyIOAKCGpAQBEUvAIBz6CgDwhohASdYEArLJgFAoYhwTQYMA4L9gEBCxHBRihgJSYV3gk6a6ac8FUzgnFi3trNV4oQxjp8xnNe+PR/QfFjO/p+RZ7NDDjLS87yvhc+98IPTjgvdyJ82e6+P+OFR0oEdZCqL4q7m+0tcSfXKVFflFdb64kF9Gp5ghL1RXnJ6kPqaVjzEa5nuyhRXxSX1Sf1gPiIEvZFccIbWkC55QVK2BeleHKUYvTc7HRstU/FZfVhtSBZ3qaEfVG8NTtaMcozM0gJ+1Se76e7i5RTitEeJeyL4oS3lNNw/qbWRwn7VPztTL+zxv94FzOzQgn7onhrDpVCdBHOFjspYZ+Kt1xXUvHTZ7VRStgXH0SseVIK0SYl7FMp0sxZPlaCOSkWGpSoL4oXs/vzPTRPza7CrfZFKSpt4/aT33Mx45S47+9B3dbmqSK+Ujhr1r4D4mWqmO8XQfEqVdQHAAAAAKo0X62c7J5gVJYAAAAAAElFTkSuQmCC"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
