import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "bootstrap/dist/js/bootstrap.esm.min.js";
import GalleryDelete from "./GalleryDelete";
import SliderDelete from "./SliderDelete";
import MpdConfig from "./MpdConfig";
import FacilitiesConfig from "./FacilitiesConfig";
import AboutUsConfig from "./AboutUsConfig";
import UpcomingNews from "./UpcomingNews";
import FeeStrConfig from "./FeeStrConfig";
import SyllabusConfig from "./SyllabusConfig";
import ExamNoticeConfig from "./ExamNoticeConfig";
import DirectorConfig from "./DirectorConfig";
import PrincipalConfig from "./PrincipalConfig";
import AcademicCalenderConfig from "./AcademicCalenderConfig";
import LinkidineConfig from "./LinkidineConfig";
import WhatsappConifg from "./WhatsappConifg";
import FacebookConfig from "./FacebookConfig";
import TwitterConfig from "./TwitterConfig";
import YoutubeConfig from "./YoutubeConfig";
import MapLocationConfig from "./MapLocationConfig";

function AdminMainLayout(props) {
  const [post, setPost] = useState({
    id: "",
    startdate: "",
    enddate: "",
    heading: "",
    subheading: "",
    content: "",
    contenturl: "",
    pdfurl: "",
    contenttype: "",
    schoolid: "gip",
    imageurl: "",
    active: false,
  });

  const [submitMessage, setSubmitMessage] = useState("");
  const [contentTypeError, setContentTypeError] = useState(false);
  // #################   for contenttype with api #################
  // const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [existingData, setExistingData] = useState(null); // State to store existing data for the selected content type
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPost({ ...post, [name]: value });

    if (name === "contenttype") {
      setPost({ ...post, [name]: value });
    } else {
      setPost({ ...post, [name]: value });
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setPost({ ...post, [name]: files[0] });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setPost({ ...post, [name]: checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!post.contenttype) {
      setContentTypeError(true); // Set content type error to true if content type is not selected
      return; // Do not proceed with form submission
    } else {
      setContentTypeError(false); // Reset content type error if content type is selected
    }
    try {
      if (post.contenttype === "aboutus" && existingData) {
        // alert("Unable to upload more than one data for this content type");
        return;
      }
      const formData = new FormData();
      for (const key in post) {
        formData.append(key, post[key]);
      }
      await axios.post(
        "https://vjeducation.co.in/webapi/contentlist/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setSubmitMessage("Request successful");
    } catch (error) {
      console.error("Error making request:", error);
      setSubmitMessage("Error making request");
    }
  };

  useEffect(() => {
    if (submitMessage) {
      const liveToast = new Toast(document.getElementById("liveToast")); // Create a new Toast instance
      liveToast.show(); // Show the toast
      setTimeout(() => {
        liveToast.hide(); // Hide the toast after 5 seconds
        setSubmitMessage(""); // Reset the submit message
        document.querySelector('input[type="file"]').value = ""; // Clear the file input field
      }, 5000);
      setPost({
        id: "",
        startdate: "",
        enddate: "",
        heading: "",
        subheading: "",
        content: "",
        contenturl: "",
        pdfurl: "",
        contenttype: "",
        schoolid: "gip",
        imageurl: "", // Clear the file input field
        active: "",
      });
    }
  }, [submitMessage]);

  // #################   for contenttype with api #################
  // useEffect(() => {
  //   const fetchContentTypeOptions = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://vjeducation.co.in/webapi/contentlist/"
  //       );
  //       setContentTypeOptions(response.data);
  //     } catch (error) {
  //       console.error("Error fetching content type options:", error);
  //     }
  //   };

  //   fetchContentTypeOptions();
  // }, []);
  return (
    <>
      <section className="adj_sec Admin">
        <div className="container tabss">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            UPDATE
          </button>

          <form onSubmit={handleSubmit} className="border-0">
            <div
              class="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-body">
                    {/* <Admin /> */}

                    <fieldset>
                      <div class="form-group d-flex align-items-center my-2">
                        <label class="col-md-3 control-label ">
                          Contenttype
                        </label>

                        <div class="col-md-9">
                          <select
                            name="contenttype"
                            onChange={handleInputChange}
                            value={post.contenttype}
                            class={`form-control clear-input ${
                              contentTypeError
                                ? "is-invalid"
                                : post.contenttype
                                ? "is-valid"
                                : ""
                            }`}
                          >
                            <option value="" selected="">
                              --------
                            </option>

                            <option value="upcoming news">upcoming news</option>

                            <option value="announcement">announcement</option>

                            <option value="slider">slider</option>

                            <option value="event">event</option>

                            <option value="gallery">gallery</option>

                            <option value="principal">principal</option>

                            <option value="director">director</option>

                            <option value="youtube">youtube</option>

                            <option value="facebook">facebook</option>

                            <option value="instagram">instagram</option>

                            <option value="twitter">twitter</option>

                            <option value="linkedin">linkedin</option>

                            <option value="whatsapp">whatsapp</option>

                            <option value="clalender">clalender</option>

                            <option value="syllabus">syllabus</option>

                            <option value="feestructure">feestructure</option>

                            <option value="toppers">toppers</option>

                            <option value="alumini">alumini</option>

                            <option value="facilities">facilities</option>

                            <option value="mandetory">mandetory</option>

                            <option value="aboutus">aboutus</option>

                            <option value="logo">logo</option>

                            <option value="download">download</option>

                            <option value="others">others</option>

                            <option value="other1">Exam Notice</option>

                            <option value="other2">Map Location</option>

                            <option value="other3">other3</option>
                          </select>
                          {contentTypeError && (
                            <div class="invalid-feedback">
                              Please select a content type
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Startdate</label>

                        <div class="col-md-9">
                          <input
                            name="startdate"
                            class="form-control clear-input"
                            type="date"
                            value={post.startdate}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "slider" ||
                              post.contenttype === "principal" ||
                              post.contenttype === "director" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "facilities" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === "aboutus" ||
                              post.contenttype === "logo" ||
                              post.contenttype === ""
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Enddate</label>

                        <div class="col-md-9">
                          <input
                            name="enddate"
                            class="form-control clear-input"
                            type="date"
                            value={post.enddate}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "slider" ||
                              post.contenttype === "principal" ||
                              post.contenttype === "director" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "toppers" ||
                              post.contenttype === "facilities" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === "aboutus" ||
                              post.contenttype === "logo" ||
                              post.contenttype === ""
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Heading</label>

                        <div class="col-md-9">
                          <input
                            name="heading"
                            class="form-control clear-input"
                            type="text"
                            value={post.heading}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "" ||
                              existingData
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">
                          Subheading
                        </label>

                        <div class="col-md-9">
                          <input
                            name="subheading"
                            class="form-control clear-input"
                            type="text"
                            value={post.subheading}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "syllabus" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "clalender" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === "" ||
                              existingData
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Content</label>

                        <div class="col-md-9">
                          <input
                            name="content"
                            class="form-control clear-input"
                            type="text"
                            value={post.content}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "syllabus" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "clalender" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "clalender" ||
                              post.contenttype === "other1" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === "logo" ||
                              post.contenttype === "" ||
                              existingData
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">
                          Contenturl
                        </label>

                        <div class="col-md-9">
                          <input
                            name="contenturl"
                            class="form-control clear-input"
                            type="text"
                            value={post.contenturl}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "announcement" ||
                              post.contenttype === "slider" ||
                              post.contenttype === "syllabus" ||
                              post.contenttype === "principal" ||
                              post.contenttype === "director" ||
                              post.contenttype === "clalender" ||
                              post.contenttype === "feestructure" ||
                              post.contenttype === "toppers" ||
                              post.contenttype === "alumini" ||
                              post.contenttype === "facilities" ||
                              post.contenttype === "other1" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "aboutus" ||
                              post.contenttype === "logo" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === ""
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Pdfurl</label>

                        <div class="col-md-9">
                          <input
                            className="form-control clear-input"
                            name="pdfurl"
                            type="file"
                            onChange={handleFileChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "upcoming news" ||
                              post.contenttype === "announcement" ||
                              post.contenttype === "slider" ||
                              post.contenttype === "event" ||
                              post.contenttype === "principal" ||
                              post.contenttype === "director" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "toppers" ||
                              post.contenttype === "alumini" ||
                              post.contenttype === "facilities" ||
                              post.contenttype === "clalender" ||
                              post.contenttype === "aboutus" ||
                              post.contenttype === "others" ||
                              post.contenttype === "other1" ||
                              post.contenttype === "logo" ||
                              post.contenttype === ""
                            }
                          />
                        </div>
                      </div>
                      {/* ################# for contenttype with api   ################## */}
                      {/* <div class="form-group d-flex align-items-center my-2">
            <label class="col-md-3 control-label">Contenttype</label>
            <div class="col-md-9">
              <select
                name="contenttype"
                onChange={handleInputChange}
                value={post.contenttype}
                class={`form-control clear-input ${
                  contentTypeError
                    ? "is-invalid"
                    : post.contenttype
                    ? "is-valid"
                    : ""
                }`}
              >
                <option value="" selected>
                  --------
                </option>
                {contentTypeOptions.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.contenttype}
                  </option>
                ))}
              </select>
            </div>
            {contentTypeError && (
              <div class="invalid-feedback">Please select a content type</div>
            )}
          </div> */}

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Schoolid</label>

                        <div class="col-md-9">
                          <input
                            name="schoolid"
                            class="form-control"
                            type="text"
                            // value={post.schoolid}
                            value="gip"
                            onChange={handleInputChange}
                            disabled
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 ">
                        <label class="col-md-3 control-label ">Imageurl</label>

                        <div class="col-md-9">
                          {/* <input
                      name="imageurl"
                      type="file"
                      onChange={handleFileChange}
                    /> */}

                          <input
                            name="imageurl"
                            className="form-control clear-input"
                            type="file"
                            onChange={handleFileChange}
                            disabled={
                              post.contenttype === "facebook" ||
                              post.contenttype === "facebook" ||
                              post.contenttype === "youtube" ||
                              post.contenttype === "instagram" ||
                              post.contenttype === "twitter" ||
                              post.contenttype === "syllabus" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "linkedin" ||
                              post.contenttype === "whatsapp" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "upcoming news" ||
                              post.contenttype === "feestructure" ||
                              post.contenttype === "other3" ||
                              post.contenttype === "mandetory" ||
                              post.contenttype === "" ||
                              existingData
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-center my-2 horizontal-checkbox ">
                        <label class="col-md-3 control-label ">Active</label>

                        <div class="col-md-9">
                          <input
                            type="checkbox"
                            name="active"
                            // value="true"
                            value={post.active}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    </fieldset>

                    <div
                      className="position-fixed top-0 end-0 p-3"
                      style={{ zIndex: 11 }}
                    >
                      <div
                        id="liveToast"
                        className={`toast bg-success ${
                          submitMessage ? "show" : "hide"
                        }`}
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                      >
                        <div className="toast-header">
                          <strong className="me-auto">Submission Status</strong>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="toast-body text-white">
                          {submitMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="form-actions">
                      <button
                        type="submit"
                        class="btn btn-success js-tooltip"
                        title="Make a POST request on the Admission Api resource"
                      >
                        Submit
                      </button>
                    </div>
                    <button
                      class="btn btn-danger"
                      data-bs-target="#exampleModalToggle"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="container my-5">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Home
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Profile
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Contact
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-gallery-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-gallery"
                type="button"
                role="tab"
                aria-controls="pills-gallery"
                aria-selected="false"
              >
                Gallery
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-slider-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-slider"
                type="button"
                role="tab"
                aria-controls="pills-slider"
                aria-selected="false"
              >
                slider
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-mpd-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-mpd"
                type="button"
                role="tab"
                aria-controls="pills-mpd"
                aria-selected="false"
              >
                mpd
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-facilities-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-facilities"
                type="button"
                role="tab"
                aria-controls="pills-facilities"
                aria-selected="false"
              >
                facilities
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-aboutus-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-aboutus"
                type="button"
                role="tab"
                aria-controls="pills-aboutus"
                aria-selected="false"
              >
                About Us
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-upcoming-news-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-upcoming-news"
                type="button"
                role="tab"
                aria-controls="pills-upcoming-news"
                aria-selected="false"
              >
                Upcoming News
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-fee-str-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-fee-str"
                type="button"
                role="tab"
                aria-controls="pills-fee-str"
                aria-selected="false"
              >
                Fee Str
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-syllabus-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-syllabus"
                type="button"
                role="tab"
                aria-controls="pills-syllabus"
                aria-selected="false"
              >
                Syllabus
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-exam-notice-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-exam-notice"
                type="button"
                role="tab"
                aria-controls="pills-exam-notice"
                aria-selected="false"
              >
                Exam Notice
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-director-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-director"
                type="button"
                role="tab"
                aria-controls="pills-director"
                aria-selected="false"
              >
                Director
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-principal-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-principal"
                type="button"
                role="tab"
                aria-controls="pills-principal"
                aria-selected="false"
              >
                principal
              </button>
            </li>
            {/*  */}
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-academic-calender-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-academic-calender"
                type="button"
                role="tab"
                aria-controls="pills-academic-calender"
                aria-selected="false"
              >
                Academic Calender
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-social-media-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-social-media"
                type="button"
                role="tab"
                aria-controls="pills-social-media"
                aria-selected="false"
              >
                Social Media
              </button>
            </li>
          </ul>

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              Home
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              Profile
            </div>
            <div
              class="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <h2 class="admin_heading">Location</h2>
              <hr />
              <MapLocationConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-gallery"
              role="tabpanel"
              aria-labelledby="pills-gallery-tab"
            >
              <GalleryDelete />
            </div>

            {/*  */}

            <div
              class="tab-pane fade"
              id="pills-slider"
              role="tabpanel"
              aria-labelledby="pills-slider-tab"
            >
              <SliderDelete />
            </div>

            <div
              class="tab-pane fade"
              id="pills-mpd"
              role="tabpanel"
              aria-labelledby="pills-mpd-tab"
            >
              <h2 class="admin_heading">MPD</h2>
              <hr />
              <MpdConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-facilities"
              role="tabpanel"
              aria-labelledby="pills-facilities-tab"
            >
              <h2 class="admin_heading">Facilities</h2>
              <hr />
              <FacilitiesConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-aboutus"
              role="tabpanel"
              aria-labelledby="pills-aboutus-tab"
            >
              <h2 class="admin_heading">About Us</h2>
              <hr />
              <AboutUsConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-upcoming-news"
              role="tabpanel"
              aria-labelledby="pills-upcoming-news-tab"
            >
              <h2 class="admin_heading">Upcoming News</h2>
              <hr />
              <UpcomingNews />
            </div>

            <div
              class="tab-pane fade"
              id="pills-fee-str"
              role="tabpanel"
              aria-labelledby="pills-fee-str-tab"
            >
              <h2 class="admin_heading">Fee Structure</h2>
              <hr />
              <FeeStrConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-syllabus"
              role="tabpanel"
              aria-labelledby="pills-syllabus-tab"
            >
              <h2 class="admin_heading">Syllabus</h2>
              <hr />
              <SyllabusConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-exam-notice"
              role="tabpanel"
              aria-labelledby="pills-exam-notice-tab"
            >
              <h2 class="admin_heading">Exam Notice</h2>
              <hr />
              <ExamNoticeConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-director"
              role="tabpanel"
              aria-labelledby="pills-director-tab"
            >
              <h2 class="admin_heading">Director Message</h2>
              <hr />
              <DirectorConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-principal"
              role="tabpanel"
              aria-labelledby="pills-principal-tab"
            >
              <h2 class="admin_heading">Principal Message</h2>
              <hr />
              <PrincipalConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-academic-calender"
              role="tabpanel"
              aria-labelledby="pills-academic-calender-tab"
            >
              <h2 class="admin_heading">Academic Calender</h2>
              <hr />
              <AcademicCalenderConfig />
            </div>

            <div
              class="tab-pane fade"
              id="pills-social-media"
              role="tabpanel"
              aria-labelledby="pills-social-media-tab"
            >
              <h2 class="admin_heading">Social Media Link</h2>

              <WhatsappConifg />
              <FacebookConfig />
              <TwitterConfig />
              <YoutubeConfig />
              <LinkidineConfig />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminMainLayout;
