import React, { useState } from "react";
import "../styles/errorpage.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <section className="container-fluid p-0">
        <section class="errorwrapper p-0">
          <div class="container">
            <div id="scene" class="scene" data-hover-only="false">
              <div class="circle" data-depth="1.2"></div>
              <div class="circle2" data-depth="1.2"></div>
              <div class="one" data-depth="0.9">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="two" data-depth="0.60">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="three" data-depth="0.40">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <p class="p404" data-depth="0.50">
                404
              </p>
              <p class="p404" data-depth="0.10">
                404
              </p>
            </div>

            <div class="text">
              <article>
                <p>Uh oh! Looks like you got lost. Go back to the homepage!</p>
                <button onClick={() => window.history.back()}>
                  <i className="fas fa-long-arrow-alt-left mt-2"></i> Go Back
                </button>
              </article>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default NotFound;
