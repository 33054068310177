import React from "react";
import PropTypes from "prop-types";
// added for theme
import colorimg from "../../assets/all-images/colorimg.png";
import { GlobalStyles } from "../GlobalTheme/styles/Global";
import {
  ThemeContainer,
  ThemeButton,
} from "../GlobalTheme/styles/ThemeSwitching.styled";
import { ThemeProvider } from "styled-components";
import {
  defaults,
  dark,
  red,
  green,
  brown,
  pink,
  colorA,
  colorB,
  colorC,
  colorD,
  colorE,
  colorF,
  colorG,
  colorH,
  colorI,
  colorJ,
} from "../GlobalTheme/styles/Theme.styled";
// close
class GlobalTheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTheme: defaults,
    };
    this.HandleThemeChange = this.HandleThemeChange.bind(this);
  }

  componentDidMount() {
    const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
    if (currentTheme) {
      this.setState({ selectedTheme: currentTheme });
    }
  }

  HandleThemeChange(theme) {
    this.setState({ selectedTheme: theme });
    localStorage.setItem("current-theme", JSON.stringify(theme));
  }

  render() {
    const { selectedTheme } = this.state;

    return (
      <ThemeProvider theme={selectedTheme}>
        <GlobalStyles />

        <div className="theme_button">
          <h4 className="text-center text-danger">Theme Selector</h4>
          <ThemeContainer>
            <span>
              <img src={colorimg} alt="" className="colorimg" />
            </span>

            <ThemeButton
              className={`defaults ${
                selectedTheme === defaults ? "active" : ""
              }`}
              onClick={() => this.HandleThemeChange(defaults)}
            ></ThemeButton>
            <ThemeButton
              className={`dark ${selectedTheme === dark ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(dark)}
            ></ThemeButton>
            <ThemeButton
              className={`red ${selectedTheme === red ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(red)}
            ></ThemeButton>
            <ThemeButton
              className={`green ${selectedTheme === green ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(green)}
            ></ThemeButton>
            <ThemeButton
              className={`brown ${selectedTheme === brown ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(brown)}
            ></ThemeButton>
            <ThemeButton
              className={`pink ${selectedTheme === pink ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(pink)}
            ></ThemeButton>
            {/* new theme added */}
            <ThemeButton
              className={`colorA ${selectedTheme === colorA ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorA)}
            ></ThemeButton>
            <ThemeButton
              className={`colorB ${selectedTheme === colorB ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorB)}
            ></ThemeButton>
            <ThemeButton
              className={`colorC ${selectedTheme === colorC ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorC)}
            ></ThemeButton>
            <ThemeButton
              className={`colorD ${selectedTheme === colorD ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorD)}
            ></ThemeButton>

            <ThemeButton
              className={`colorE ${selectedTheme === colorE ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorE)}
            ></ThemeButton>

            <ThemeButton
              className={`colorF ${selectedTheme === colorF ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorF)}
            ></ThemeButton>

            <ThemeButton
              className={`colorG ${selectedTheme === colorG ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorG)}
            ></ThemeButton>

            <ThemeButton
              className={`colorH ${selectedTheme === colorH ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorH)}
            ></ThemeButton>

            <ThemeButton
              className={`colorI ${selectedTheme === colorI ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorI)}
            ></ThemeButton>

            <ThemeButton
              className={`colorJ ${selectedTheme === colorJ ? "active" : ""}`}
              onClick={() => this.HandleThemeChange(colorJ)}
            ></ThemeButton>
          </ThemeContainer>
        </div>
      </ThemeProvider>
    );
  }
}

GlobalTheme.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired,
};

export default GlobalTheme;
