import React from "react";
import Helmet from "../components/Helmet/Helmet.js";
import CommonSection from "../components/UI/CommonSection.jsx";
import SyllabusAdmin from "./Admin/SyllabusAdmin.js";

const Syllabus = () => {
  return (
    <>
      <Helmet>
        <CommonSection title="Syllabus" />
        <section id="main_news_layout">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <section class="set_new_height">
                  <div className="">
                    <SyllabusAdmin />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </Helmet>
    </>
  );
};

export default Syllabus;
