// <iframe
//   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.493908155021!2d85.51648077403107!3d25.354758225303378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f28ed4dbdc9439%3A0x114d8525a046e04c!2sSt.%20Paul&#39;s%20English%20School!5e0!3m2!1sen!2sin!4v1707039872944!5m2!1sen!2sin"
//   allowfullscreen=""
//   loading="lazy"
//   referrerpolicy="no-referrer-when-downgrade"
// ></iframe>

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import Common_URL from "../Config";
import "../styles/contact.css";

const Contact = () => {
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=other2");
        setIframeSrc(response.data[0]?.content);
        console.log(response.data[0].content);
      } catch (error) {
        console.error("Error fetching iframe src data:", error);
      }
    };

    fetchData();
  }, []);
  const map_base = "https://www.google.com/maps/embed?pb=";
  return (
    <Helmet title="Contact">
      <CommonSection title="Contact Us" />
      <section id="main_news_layout">
        <Container>
          <div className="">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6 col-12">
                <div className="company-info contact_layout">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.354030394241!2d85.74717827401544!3d24.95406674150807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f251ec3ed52737%3A0x532f22064a67d954!2sG.I.P%20Public%20School!5e0!3m2!1sen!2sin!4v1714038157671!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div class="contact contact_layout">
                  <form action="#" method="post">
                    <label for="name">Name</label>
                    <input type="text" id="name" name="name" required />

                    <label for="email">Email</label>
                    <input type="email" id="email" name="email" required />

                    <label for="number">Mobile No.</label>
                    <input type="number" id="number" name="nu,ber" required />

                    <label for="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="2"
                      required
                    ></textarea>

                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
